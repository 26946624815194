export const missions = {
    "Apollo": {
        "Path": "/Apollo/",
        "URL": "https://d1i18s816yv82y.cloudfront.net"
    },
    "Cassini" : {
        "Path": "/Cassini/",
        "URL": "https://dvr8xb2t7gqyu.cloudfront.net"
    },
    "Chandrayaan 1": {
        "Path": "/Chandrayaan_1/",
        "URL": "https://d34uoeqxvp7znu.cloudfront.net"
    },
    "Clementine": {
        "Path": "/Clementine/",
        "URL": "https://d1675vf1pwtw76.cloudfront.net"
    },
    "Galileo": {
        "Path": "/Galileo/",
        "URL": "https://d13214t6fumxeh.cloudfront.net"
    },
    "Individual Investigations": {
        "Path": "/Individual_Investigations/",
        "URL": "https://dwjtvz5c9xobz.cloudfront.net"
    },
    "InSight": {
        "Path": "/InSight/",
        "URL": "https://d1jqt0tzwio5ew.cloudfront.net"
    },
    "Juno": {
        "Path": "/Juno/",
        "URL": "https://dwy57t52b0coo.cloudfront.net"
    },
    "Korea Pathfinder Lunar Orbiter": {
        "Path": "/Korea_Pathfinder_Lunar_Orbiter/",
        "URL": "https://d34q58rwh29n57.cloudfront.net"
    },
    "Lunar Orbiter": {
        "Path": "/Lunar_Orbiter/",
        "URL": "https://dmjh6ggf608es.cloudfront.net"
    },
    "Lunar Reconnaissance Orbiter": {
        "Path": "/Lunar_Reconnaissance_Orbiter/",
        "URL": "https://d2bqlxqdvh9600.cloudfront.net"
    },
    "Magellan": {
        "Path": "/Magellan/",
        "URL": "https://d2bnparajh97tn.cloudfront.net"
    },
    "Mariner 09": {
        "Path": "/Mariner_09/",
        "URL": "https://d2gwf3noijagnq.cloudfront.net"
    },
    "Mariner 10": {
        "Path": "/Mariner_10/",
        "URL": "https://dacobg3xs3ufd.cloudfront.net"
    },
    "Mariner 69": {
        "Path": "/Mariner_69/",
        "URL": "https://di8l3ojjdiisv.cloudfront.net"
    },
    "Mars Exploration Rover": {
        "Path": "/Mars_Exploration_Rover/",
        "URL": "https://d2gq8errdqpl02.cloudfront.net"
    },
    "Mars Global Surveyor": {
        "Path": "/Mars_Global_Surveyor/",
        "URL": "https://ddzxgla979s6o.cloudfront.net"
    },
    "Mars Odyssey": {
        "Path": "/Mars_Odyssey/",
        "URL": "https://d3ou3lhewj22xx.cloudfront.net"
    },
    "Mars Pathfinder": {
        "Path": "/Mars_Pathfinder/",
        "URL": "https://d1cyq2fujau5dy.cloudfront.net"
    },
    "Mars Reconnaissance Orbiter": {
        "Path": "/Mars_Reconnaissance_Orbiter/",
        "URL": "https://d32ky7zsovnyu5.cloudfront.net"
    },
    "Mars Science Laboratory": {
        "Path": "/Mars_Science_Laboratory/",
        "URL": "https://d11vqxp9dwdszl.cloudfront.net"
    },
    "Messenger": {
        "Path": "/Messenger/",
        "URL": "https://d3fhgbbgskqro0.cloudfront.net"
    },
    "Phoenix": {
        "Path": "/Phoenix/",
        "URL": "https://d9zrpxuzslzxe.cloudfront.net"
    },
    "Viking Lander": {
        "Path": "/Viking_Lander/",
        "URL": "https://d3frj7sf7hx05c.cloudfront.net"
    },
    "Viking Orbiter": {
        "Path": "/Viking_Orbiter/",
        "URL": "https://d1nxexkqx2p6yf.cloudfront.net"
    },
    "Voyager": {
        "Path": "/Voyager/",
        "URL": "https://d3w3ur5r5cuck5.cloudfront.net"
    }
  };

export const exclude_prefixes = [
    "THEMIS/USA_NASA_PDS_ODTGEO_100XX/",
    "clem_archive/"
];