// Import our CSS
import '../scss/main.scss'

// Import JQuery. This will add the $ global variable 
import './jquery-import';

// Import all bootstrap plugins
import * as bootstrap from 'bootstrap';

import './utils';
import './error';
import './uswds-init.min';
import './uswds.min';
import { missions } from './missions.js';

// Show the <main>
$("main").show();

if (location.pathname == "/"){ 
    $.each( missions, function( key, value ) {
        var items = [];
        items.push( "<li class='usa-sidenav__item'> <a href='" + value.Path + "'>" + key + "</a></li>" );
        $( ".usa-sidenav").append( items.join( "" ) );
        });
}


var SUBDIRS = [];
$.each( missions, function( key, value ) {
    SUBDIRS.push(value.Path);
});

import getS3Data from './list';
$(function() {
    if (SUBDIRS.some(w => location.pathname.includes(w))) {
        getS3Data();
    }
});

